// pick: https://github.com/reach/router/issues/74
import { pick } from '@reach/router/lib/utils'

import { Home } from '/pages/Home'
import { Experiments } from '/pages/Experiments'
import { AboutUs } from '/pages/AboutUs'
import { Team } from '/pages/Team'
import { Cases } from '/pages/Cases'
import { Case } from '/pages/Case'
import { CaseAwardEntry } from '/pages/CaseAwardEntry'
import { Page } from '/pages/Page'
import { Articles } from '/pages/Articles'
import { Article } from '/pages/Article'
import { LegalPrivacy } from '/pages/LegalPrivacy'
import { LegalCopyright } from '/pages/LegalCopyright'
import { LegalDisclaimer } from '/pages/LegalDisclaimer'
import { NewsletterArchive } from '/pages/NewsletterArchive'
import { NotFound } from '/pages/NotFound'
import { WordMiljonair } from '/pages/WordMiljonair'
import { Dobbelsteen } from '/pages/Dobbelsteen'
import { Inventors } from '/pages/Inventors'
import { PitchCases } from './pages/PitchCases'
import { PitchCase } from './pages/PitchCase'
import { Expertise } from './pages/Expertise'

/** @type {{ [key: string]: Route }} */
export const routes = {
  home: { path: '/', reverse: () => '/', ...Home.meta },
  aboutUs: { path: '/over-ons/', reverse: () => '/over-ons/', ...AboutUs.meta },
  team: { path: '/over-ons/team/', reverse: () => '/over-ons/team/', ...Team.meta },
  experiments: { path: '/experimenten/', reverse: () => '/experimenten/', ...Experiments.meta },
  expertise: { path: '/expertises/:slug', reverse: x => `/expertises/${x.slug.current}/`, ...Expertise.meta },
  cases: { path: '/cases/', reverse: () => '/cases/', ...Cases.meta },
  case: { path: '/cases/:slug/', reverse: x => `/cases/${x.slug.current}/`, ...Case.meta },
  caseAwardEntry: { path: '/awards/:slug/', reverse: x => `/awards/${x.slug.current}/`, ...CaseAwardEntry.meta },
  page: { path: '/:slug', reverse: x => `/${x.slug.current}/`, ...Page.meta },
  articles: { path: '/inspiratie/', reverse: () => '/inspiratie/', ...Articles.meta },
  article: { path: '/inspiratie/:slug', reverse: x => `/inspiratie/${x.slug.current}/`, ...Article.meta },
  inventors: { path: '/inventors/', reverse: () => '/inventors/', ...Inventors.meta },
  legalPrivacy: { path: '/privacy/', reverse: () => '/privacy/', ...LegalPrivacy.meta },
  legalCopyright: { path: '/copyright/', ...LegalCopyright.meta },
  legalDisclaimer: { path: '/disclaimer/', reverse: () =>  '/disclaimer/', ...LegalDisclaimer.meta },
  newsletterArchive: { path: '/nieuwsbrief-archief/', reverse: () => '/nieuwsbrief-archief/', ...NewsletterArchive.meta },
  wordMiljonair: { path: '/wordmiljonair/', reverse: () => '/wordmiljonair/', ...WordMiljonair.meta },
  dobbelsteen: { path: '/dobbelsteen/', reverse: () => '/dobbelsteen/', ...Dobbelsteen.meta },
  unsupportedBrowser: { path: '/update-je-browser/', reverse: () => '/update-je-browser/', unsupportedBrowser: true },
  pitchCases: { path: '/STLCases', reverse: () => '/STLCases', ...PitchCases.meta },
  pitchCase: { path: '/STLCases/:slug', reverse: x => `/STLCases/${x.slug.current}/`, ...PitchCase.meta },
  notFound: NotFound.meta
}

export function matchRoute(uri) {
  return pick(Object.values(routes).filter(x => x.path), uri)
}

export function determineDocumentPath({ document, routes }) {
  return routes[document._type].reverse(document)
}

/**
 * @typedef {object} Route
 * @property {string} [path]
 * @property {() => string} [reverse]
 * @property {boolean} [unsupportedBrowser]
 * @property {string} [title]
 * @property {string} [description]
 */
