import { LinkButtonWhite, LinkTextArrow } from '/components/buildingBlocks/Link'
import { BlockContent } from '/components/buildingBlocks/BlockContent'
import  { animated, useSpring, config } from '@react-spring/web'
import { useElementSize }  from '@kaliber/use-element-size'
import { HeadingMd } from '/components/buildingBlocks/Heading'
import { ButtonCircle } from '/components/buildingBlocks/ButtonCircle'
import { IconRightArrow } from '/components/buildingBlocks/Icon'

import phoneImage from './faq-phone.png'

import styles from './FrequentlyAskedQuestions.css'

export function FrequentlyAskedQuestions({ faq, contactName, contactFunction }) {
  const [isExpanded, setIsExpanded] = React.useState(0)
  const { items } = faq

  return (
    <div className={styles.component}>
      <h2 className={cx(styles.title, styles.titleComponent)}>Benieuwd naar<br />onze aanpak?</h2>
      <div className={styles.contact}>
        <p className={styles.contactText}>
          We gaan graag met je in gesprek. Neem contact op met {contactName}, {contactFunction}
        </p>
        <div className={styles.buttons}>
          <LinkButtonWhite
            to='tel:+31302876400'
            datax='link-to-call'
            dataxcontext='faq'
          >
            Bel 030 287 64 00
          </LinkButtonWhite>
          <LinkTextArrow
            to='mailto:hallo@kaliber.net'
            datax='link-to-mail'
            dataxcontext='faq'
            target='_blank'
          >
            Mail naar hallo@kaliber.net
          </LinkTextArrow>
        </div>
      </div>
      <div className={styles.faq}>
        <div className={styles.faqInner}>
          <h3 className={cx(styles.title, styles.titleFaq)}>Veelgestelde vragen</h3>
          <div>
            {items?.map((item, index) => (
              <FaqItem
                key={item._key}
                question={item.question}
                answer={item.answer}
                onExpandedChange={() => handleExpandedChange(index)}
                isExpanded={index === isExpanded}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        {/* //TODO: Image placement on mobile, based on new design */}
        <img src={phoneImage} className={styles.image} alt='telefoon met bloemen' loading="lazy" />
      </div>
    </div>
  )

  function handleExpandedChange(index) {
    isExpanded === index
      ? setIsExpanded(-1)
      : setIsExpanded(index)
  }
}

function FaqItem({ isExpanded, onExpandedChange, question, answer }) {
  const { size: { height }, ref: elementRef } = useElementSize()

  const style = useSpring({
    height: `${isExpanded ? height : 0}px`,
    config: config.gentle
  })

  return (
    <div className={styles.componentFaqItem}>
      <div className={styles.questionHeader}>
        <HeadingMd h='4'>{question}</HeadingMd>
        <div className={styles.buttonWrapper}>
          <ButtonCircle onClick={onExpandedChange} layoutClassName={styles.buttonLayout}>
            <div className={cx(styles.icon, isExpanded && styles.isExpanded)}>
              <IconRightArrow />
            </div>
          </ButtonCircle>
        </div>
      </div>
      <animated.div className={styles.answer} {...{ style }}  >
        <div className={styles.answerInner} ref={elementRef}>
          <BlockContent blocks={answer} />
        </div>
      </animated.div>
    </div>
  )
}
