import { routes } from '/routes'
import { Router, ServerLocation, LocationProvider } from '@reach/router'
import { Home } from '/pages/Home'
import { AboutUs } from '/pages/AboutUs'
import { Experiments } from '/pages/Experiments'
import { Articles } from '/pages/Articles'
import { Article } from '/pages/Article'
import { Cases } from '/pages/Cases'
import { Case } from '/pages/Case'
import { CaseAwardEntry } from '/pages/CaseAwardEntry'
import { Page } from '/pages/Page'
import { LegalPrivacy } from '/pages/LegalPrivacy'
import { LegalCopyright } from '/pages/LegalCopyright'
import { LegalDisclaimer } from '/pages/LegalDisclaimer'
import { NewsletterArchive } from '/pages/NewsletterArchive'
import { NotFound } from '/pages/NotFound'
import { Team } from '/pages/Team'
import { WordMiljonair } from '/pages/WordMiljonair'
import { Dobbelsteen } from '/pages/Dobbelsteen'
import { Inventors } from '/pages/Inventors'
import { ConfigContext } from '/machinery/useConfig'
import { HasMouseContextProvider } from '/machinery/HasMouse'
import { ViewportContextProvider } from '/machinery/Viewport'
import { useUserIsTabbing } from '/machinery/useUserIsTabbing'
import { MenuStateContextProvider } from '/machinery/MenuState'
import { PageRouteDataContextProvider, usePageRouteData } from '/machinery/PageRouteData'
import { TrackingSettingsContextProvider } from '/machinery/TrackingSettings'
import { Menu } from '/components/pageOnly/Menu'
import { WindowScrollContextProvider } from '/machinery/WindowScroll'
import { ModalContextProvider, ModalContainer } from '/components/Modal'
import { SanityClientContextProvider } from '/machinery/SanityClient'
import { BREAKPOINTS } from '/constants'
import { ManageScrollPosition } from './machinery/ManageScrollPosition'
import { PitchCases } from './pages/PitchCases'
import { PitchCase } from './pages/PitchCase'
import { Expertise } from './pages/Expertise'

import styles from './App.css'

export default function App({ config, path, data, status, trackingSettings, menu }) {
  const modalRef = React.useRef()
  return (
    <AppContext {...{ path, data, status, modalRef, config, trackingSettings }}>
      <Content {...{ menu, modalRef }} />
    </AppContext>
  )
}

function AppContext({ path, data, status, modalRef, config, trackingSettings, children }) {
  const value = React.useMemo(() => ({ modalRef }), [modalRef])
  return (
    <ConfigContext.Provider value={config}>
      <ProvideLocation url={path}>
        <SanityClientContextProvider config={config.sanity}>
          <PageRouteDataContextProvider {...{ data, status }}>
            <HasMouseContextProvider>
              <ViewportContextProvider breakpoints={BREAKPOINTS}>
                <WindowScrollContextProvider>
                  <MenuStateContextProvider>
                    <TrackingSettingsContextProvider {...{ trackingSettings }}>
                      <ModalContextProvider {...{ value }}>
                        {children}
                      </ModalContextProvider>
                    </TrackingSettingsContextProvider>
                  </MenuStateContextProvider>
                </WindowScrollContextProvider>
              </ViewportContextProvider>
            </HasMouseContextProvider>
          </PageRouteDataContextProvider>
        </SanityClientContextProvider>
      </ProvideLocation>
    </ConfigContext.Provider>
  )
}

function Content({ menu, modalRef }) {
  const { status } = usePageRouteData()
  const userIsTabbing = useUserIsTabbing()

  return (
    <>
      <ManageScrollPosition />
      <div className={styles.component} data-context-disable-outline={!userIsTabbing}>
        {status === 404 ? <NotFound /> : (
          <Router primary={false}>
            <AboutUs path={routes.aboutUs.path} />
            <Article path={routes.article.path} />
            <Articles path={routes.articles.path} />
            <Case path={routes.case.path} />
            <CaseAwardEntry path={routes.caseAwardEntry.path} />
            <Cases path={routes.cases.path} />
            <Dobbelsteen path={routes.dobbelsteen.path} />
            <Experiments path={routes.experiments.path} />
            <Expertise path={routes.expertise.path} />
            <Home path={routes.home.path} />
            <Inventors path={routes.inventors.path} />
            <LegalCopyright path={routes.legalCopyright.path} />
            <LegalDisclaimer path={routes.legalDisclaimer.path} />
            <LegalPrivacy path={routes.legalPrivacy.path} />
            <NewsletterArchive path={routes.newsletterArchive.path} />
            <NotFound default />
            <Page path={routes.page.path} />
            <PitchCase path={routes.pitchCase.path} />
            <PitchCases path={routes.pitchCases.path} />
            <PitchCases path={routes.pitchCases.path} />
            <Team path={routes.team.path} />
            <WordMiljonair path={routes.wordMiljonair.path} />
          </Router>
        )}
        <Menu layoutClassName={styles.menu} items={menu.items} />
        <ModalContainer ref={modalRef} />
      </div>
    </>
  )
}

function ProvideLocation({ url, children }) {
  return (typeof window !== 'undefined')
    ? <LocationProvider {...{ children }} />
    : <ServerLocation {...{ url, children }} />
}
