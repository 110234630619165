import { useMediaQuery } from '@kaliber/use-media-query'
import { useSpring, animated } from '@react-spring/web'
import { routes, determineDocumentPath } from '/routes'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { CustomCursor } from '/components/buildingBlocks/CustomCursor'
import { useSanityClient } from '/machinery/SanityClient'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'

import mediaStyles from '/cssGlobal/media.css'
import styles from './CasesGrid.css'

export function CasesGrid({ cases }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const CasesComponent = isViewportMd ? CasesDesktop : CasesMobile

  return (
    <CasesComponent {...{ cases }} />
  )
}

function CaseLandscape({ image, client, title, slug, layoutClassName = undefined }) {
  return (
    <CaseBase
      {...{ image, client, title, slug }}
      aspectRatio='landscape'
      className={cx(styles.componentCaseLandscape, layoutClassName)}
    />
  )
}

function CasePortrait({ image, client, title, slug, layoutClassName = undefined }) {
  return (
    <CaseBase
      {...{ image, client, title, slug }}
      aspectRatio='portrait'
      className={cx(styles.componentCasePortrait, layoutClassName)}
    />
  )
}

function CasesDesktop({ cases }) {
  const { firstList, secondList } = splitArrayByOrder(cases || [])

  const getDisplayType = (index) => {
    const positionInBlock = index % 4
    return positionInBlock < 3 ? 'landscape' : 'portrait'
  }

  return (
    <ul className={styles.componentDesktop}>
      {[...firstList, ...secondList].map(({ id, slug, title, client, image }, index) => {
        const displayType = getDisplayType(index)

        return (
          <li key={id} className={styles.case}>
            {displayType === 'landscape'
              ? <CaseLandscape {...{ slug, title, client, image }} />
              : <CasePortrait {...{ slug, title, client, image }} />
            }
          </li>
        )
      })}
    </ul>
  )
}

function CasesMobile({ cases }) {
  return (
    <ul className={styles.componentMobile}>
      {cases.map(({ id, slug, title, client, image }, index) => {
        return (
          <li key={id} className={styles.case}>
            <CaseLandscape key={id} {...{ slug, title, client, image }} />
          </li>
        )
      })}
    </ul>
  )
}

function CaseBase({ image, client, title, slug, className, aspectRatio }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [0.1] })
  const link = determineDocumentPath({ document: { _type: 'case', slug: { current: slug } }, routes })
  const hasImage = image?.asset?.url

  const contentSpring = useSpring({
    to: {
      opacity: wasInViewport ? 1 : 0,
    },
    delay: 400,
    config: { tension: 75, friction: 40 }
  })

  const imageSpring = useSpring({
    to: {
      transform: wasInViewport ? 'scale(1)' : 'scale(1.1)',
      opacity: wasInViewport ? 1 : 0,
      clipPath: wasInViewport ? 'inset(0% 0% 0% 0%)' : 'inset(50% 0% 0% 0%)'
    },
    config: { tension: 100, friction: 35 }
  })

  return (
    <CustomCursor>
      <a href={link} className={cx(styles.componentCaseBase, className, wasInViewport && styles.isInView)} ref={elementRef}>
        {hasImage && <animated.div style={imageSpring} className={styles.imageContainer}><CaseImage {...{ image, aspectRatio }} layoutClassName={styles.imageLayout} /></animated.div>}
        <div className={styles.content}>
          <animated.div style={contentSpring} className={styles.client}>{client}</animated.div>
          <animated.div style={contentSpring} className={styles.title}>
            {title}
          </animated.div>
        </div>
      </a>
    </CustomCursor>
  )
}

function CaseImage({ image, aspectRatio, layoutClassName = undefined }) {
  const { imageBuilder } = useSanityClient()
  const src = image
    ? aspectRatio === 'landscape'
      ? imageBuilder.image(image).width(700).height(500).url()
      : imageBuilder.image(image).width(700).height(1200).url()
    : null

  return (
    <div className={cx(styles.componentCaseImage, layoutClassName)}>
      <ImageObjectFit layoutClassName={styles.imageLayout} {...{ src }} alt='image' />
    </div>
  )
}

function splitArrayByOrder(items) {
  let firstList = []
  let secondList = []

  if (items.length > 0) {
    firstList = items.filter((_, index) => index % 2 === 0)
    secondList = items.filter((_, index) => index % 2 !== 0)
  }

  return { firstList, secondList }
}
