import { BlockContent } from '/components/buildingBlocks/BlockContent'

import styles from './Content.css'

export function Content({ title, content, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      {title && (
        <h2 className={styles.title}>{title}</h2>
      )}
      {content && (
        <div className={styles.content}>
          <BlockContent blocks={content} />
        </div>
      )}
    </div>
  )
}
