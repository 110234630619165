import { useSanityClient } from '/machinery/SanityClient'
import { Icon } from '/components/buildingBlocks/Icon'
import { Image } from '/components/Image'

import styles from './Quote.css'

import quotationMarkStart from '/images/quote.raw.svg'
import quotationMarkend from '/images/quoteRotated.raw.svg'

export function Quote({ quote, cite: source, image, quatationColor = 'yellow' }) {
  const { imageBuilder } = useSanityClient()

  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        <div className={styles.quote}>
          {quote && (
            <blockquote className={styles.blockquote}>
              {quote}
            </blockquote>
          )}
          {source && (
            <div className={styles.source}>
              {image?.asset && (
                <div className={styles.image}>
                  <Image layoutClassName={styles.imageLayout} builder={imageBuilder} {...{ image }} alt={source} />
                </div>
              )}
              <p>{source}</p>
            </div>
          )}
        </div>
        <div className={cx(styles.mark, styles.markStart)} style={{ color: `var(--color-${quatationColor})` }}>
          <Icon icon={quotationMarkStart} layoutClassName={styles.markLayout} />
        </div>
        <div className={cx(styles.mark, styles.markEnd)} style={{ color: `var(--color-${quatationColor})` }}>
          <Icon icon={quotationMarkend} layoutClassName={styles.markLayout} />
        </div>
      </div>
    </div>
  )
}
