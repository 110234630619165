import { LinkButtonWhite } from '/components/buildingBlocks/Link'
import { Icon } from '/components/buildingBlocks/Icon'
import styles from './Cta.css'

import iconPhone from '/components/pageOnly/assets/phone.raw.svg'
import iconMail from '/components/pageOnly/assets/mail.raw.svg'

export function Cta({ text, bgColor = undefined, textColor = undefined }) {
  return (
    <section className={(styles.component)} style={{ backgroundColor: bgColor, color: textColor }}>
      <div className={styles.container}>
        <p className={styles.text}>{text}</p>
        <div className={styles.links}>
          <LinkButtonWhite to='mailto:hallo@kaliber.net'>
            <Icon icon={iconMail} />
            Stuur een e-mail
          </LinkButtonWhite>
          <LinkButtonWhite to='tel:+31302876400'>
            <Icon icon={iconPhone} />
            Bel 030-2876400
          </LinkButtonWhite>
        </div>
      </div>
    </section>
  )
}

