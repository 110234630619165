import { HoverCarousel } from './HoverCarousel'
import { ButtonCircle } from '/components/buildingBlocks/ButtonCircle'
import { LinkButtonWhite } from '/components/buildingBlocks/Link'
import { Image } from '/components/Image'
import { useSanityClient } from '/machinery/SanityClient'
import { IconRightArrow } from '/components/buildingBlocks/Icon'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { animated, useSpring, config } from 'react-spring'
import { useKeenSlider } from 'keen-slider/react'
import { routes, determineDocumentPath } from '/routes'
import { lerp } from '@kaliber/math'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ItemsCarousel.css'

export function ItemsCarousel({ cases, type, title = undefined  }) {
  const isMd = useMediaQuery(mediaStyles.viewportMd)
  const { ref: scrollProgressionRef, progression } = useAnimatedScrollProgression()

  return (
    <div className={styles.component} ref={scrollProgressionRef}>
      <div className={styles.headingWrapper}>
        <animated.h2
          className={styles.heading}
          style={{ transform: isMd &&  progression.to(x =>
            `translateX(${lerp({ start: 50, end: -10, input: x })}%)`)
          }}
        >
          {title || 'Blijf ontdekken'}
        </animated.h2>
      </div>
      {isMd
        ? <HoverCarousel {...{ cases, type }} />
        : <DragCarousel {...{ cases, type }} />
      }
      <div className={styles.linkButtonWrapper}>
        <LinkButtonWhite to={routes.cases.path} layoutClassName={styles.buttonLayout}>
          Bekijk alle cases
        </LinkButtonWhite>
      </div>
    </div>
  )
}

function DragCarousel({ cases, type }) {
  const [progressProps, progressApi] = useSpring(() => ({ progress: 0 }))

  const [carouselRef] = useKeenSlider({
    slides: {
      perView: 1.2,
      spacing: 20,
    },
    selector: x => x.childNodes,
    detailsChanged: (x) => {
      progressApi.start({ progress: x.track.details.progress })
    }
  })

  return (
    <div className={styles.componentDragCarousel}>
      <div className={styles.layout}>
        <div ref={carouselRef} className={cx(styles.carousel, 'keen-slider')}>
          {cases.map((item, i) => {
            const link = determineDocumentPath({ document: { _type: type, slug: { current: item.slug } }, routes })
            return (
              <div key={i} className={cx(styles.item, 'keen-slider__slide')}>
                {item?.image && (
                  <DragCarouselImage
                    image={item.image}
                    layoutClassName={styles.imageLayout}
                  />
                )}
                <div className={styles.text}>
                  <div className={styles.client}>{item.client}</div>
                  <a href={link} className={styles.title} data-x='link-to-case'>{item.title}</a>
                </div>
                <ButtonCircle layoutClassName={styles.buttonLayout}>
                  <IconRightArrow />
                </ButtonCircle>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

function DragCarouselImage({ image, layoutClassName }) {
  const { imageBuilder } = useSanityClient()
  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      {image?.asset &&  <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.coverImageLayout} />}
    </div>
  )
}

function useAnimatedScrollProgression() {
  const [{ progression }, spring] = useSpring(() => ({ progression: 0, config: config.molasses }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.top(0) },
    onChange(progression) {
      spring.start({ progression: easeOutQuint(progression) })
    }
  })

  return { ref, progression }
}

function easeOutQuint(x) {
  return 1 - Math.cos((x * Math.PI) / 2)
}
