import styles from './SiteFooter.css'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { HeadingMd } from '/components/buildingBlocks/Heading'
import { UnorderedList } from '/components/buildingBlocks/UnorderedList'
import { LinkArrowBorderTransition, LinkBorderTransition } from '/components/buildingBlocks/Link'
import { routes } from '/routes'
import { SchemaOrgOrganizationKaliber } from '/components/buildingBlocks/SchemaOrgOrganizationKaliber'
import { themeColors } from '/machinery/Colors.js'
import cx from 'classnames'

import logoDda from '/pages/assets/footer/dda.svg'
import logoIso from '/pages/assets/footer/iso.svg'
import logoVia from '/pages/assets/footer/via.svg'
import logoFonk from '/pages/assets/footer/fonk.svg'
import logoEmerce from '/pages/assets/footer/emerce.svg'

export function SiteFooter() {
  return (
    <footer className={styles.component}>
      <CenteredContainer width='lg'>
        <div className={styles.inner}>
          <div className={styles.sections}>
            <section className={styles.section}>
              <SectionHeading>Locatie</SectionHeading>
              <address>
                <Link to='https://goo.gl/maps/cDxTsRcoDkwZjLcr8'>Zeedijk 19</Link><br />
                <Link to='https://goo.gl/maps/cDxTsRcoDkwZjLcr8'>3513 DA Utrecht</Link>
              </address>
            </section>
            <section className={styles.section}>
              <SectionHeading>Contact</SectionHeading>
              <address>
                <Link to='tel:+31302876400'>030 287 64 00</Link><br />
                <Link to='mailto:hallo@kaliber.net'>hallo@kaliber.net</Link>
                <br /><br />
                Solliciteren of samenwerken?<br />
                <Link to='https://werkenbij.kaliber.net'>werkenbij.kaliber.net</Link>
              </address>
            </section>
            <section className={styles.section}>
              <SectionHeading>Social</SectionHeading>
              <List>
                <ListLink to='https://www.facebook.com/kaliberinteractive/'>Facebook</ListLink>
                <ListLink to='https://www.instagram.com/kaliberinteractive/'>Instagram</ListLink>
                <ListLink to='https://bsky.app/profile/kaliber.net'>Bluesky</ListLink>
                <ListLink to='https://www.linkedin.com/company/kaliber-interactive/'>LinkedIn</ListLink>
                <ListLink to='https://github.com/kaliberjs'>GitHub</ListLink>
              </List>
            </section>
            <section className={styles.section}>
              <SectionHeading>Nieuwsbrief</SectionHeading>
              <div className={styles.newsletterDiv}>
                <NewsLetterLink layoutClassName={styles.link}
                  to='https://mailchi.mp/kaliber/inschrijven-ontdekkingen-van-kaliber'
                >
                  Schrijf je in!
                </NewsLetterLink>
                <br />
                <Link to={routes.newsletterArchive.path}>Check de vorige edities hier </Link>
              </div>
            </section>
          </div>
          <div className={styles.linksAndLogos}>
            <div className={cx(styles.legalLinks, styles.legalLinks)}>
              <LegalLink to={routes.legalDisclaimer.path}>Disclaimer</LegalLink>
              <LegalLink to={routes.legalCopyright.path}>Copyright</LegalLink>
              <LegalLink to={routes.legalPrivacy.path}>Privacy</LegalLink>
            </div>
            <div className={styles.logos}>
              <img className={styles.logo} src={logoFonk} alt='Fonk' loading="lazy" />
              <img className={styles.logo} src={logoEmerce} alt='Emerce' loading="lazy" />
              <img className={cx(styles.logo, styles.logoIso)} src={logoIso} alt='ISO 27001 gecertificeerd' loading="lazy" />
              <img className={styles.logo} src={logoDda} alt='Lid van Dutch Digital Agencies' loading="lazy" />
              <img className={styles.logo} src={logoVia} alt='Lid van VIA' loading="lazy" />
            </div>
          </div>
        </div>
      </CenteredContainer>
      <SchemaOrgOrganizationKaliber />
    </footer>
  )
}

function SectionHeading({ children }) {
  return <div className={styles.componentSectionHeading}><HeadingMd h={2}>{children}</HeadingMd></div>
}

function List({ children }) {
  return <UnorderedList>{children}</UnorderedList>
}

function ListLink({ to, children }) {
  return <li><Link {...{ to, children }} /></li>
}

function Link({ to, children }) {
  return <span className={styles.componentLink}><LinkBorderTransition {...{ to, children }} target='_blank' color={themeColors.pink} /></span>
}

function LegalLink({ to, children, target = undefined }) {
  return <LinkBorderTransition {...{ to, children, target }} color={themeColors.pink} />
}

function NewsLetterLink({ to, children, layoutClassName }) {
  return (
    <span className={cx(styles.componentNewsLetterLink, layoutClassName)}>
      <LinkArrowBorderTransition {...{ to, children }} target='_blank' color={themeColors.pink} />
    </span>
  )
}
