import { useElementSize } from '@kaliber/use-element-size'

export function useVideoQualityLevel({ sources }) {
  const [src, setSrc] = React.useState(null)
  const { ref, size: { width, height } } = useElementSize()

  React.useEffect(
    () => {
      if (!sources) return null

      const sourceEntriesAsc = Object.entries(sources).sort(([a], [b]) => Number(a) - Number(b))
      const [fallbackEntry] = sourceEntriesAsc

      if (!fallbackEntry) {
        setSrc(null)
        return
      }

      const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection

      if (connection?.saveData) {
        setSrc(fallbackEntry[1] ?? null)
        return
      }

      const connectionType = connection?.effectiveType ?? '4g'
      const maxLevel = {
        'slow-2g': 0,
        '2g': 0,
        '3g': 1,
        '4g': 3
      }[connectionType]

      const neededLevel = [
        ...sourceEntriesAsc,
        [Infinity, null]
      ].findIndex(([size]) => size >= Math.min(width, height))

      const [, source] = sourceEntriesAsc[Math.min(neededLevel, maxLevel)]
      setSrc(source)
    },
    [width, height, sources]
  )

  return { ref, src }
}
