import groq from 'groq'

import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { getSingletonTypeById } from '/machinery/groqSnippets'
import { routes } from '/routes'

import { LinkButtonWhite } from '/components/buildingBlocks/Link'
import { awards } from '/components/home/assets/awards'
import { clients } from '/components/home/assets/clients'
import { introGifAssets } from '/components/home/assets/introGifAssets'
import { Awards, AwardsItem } from '/components/home/Awards'
import { Clients } from '/components/home/Clients'
import { ContentContainer } from '/components/home/pageOnly/ContentContainer'
import { ContainerDark, ContainerLight, ContainerColored } from '/components/home/Container'
import { Hero } from '/components/home/pageOnly/Hero/Hero'
import { IntroWithButton, IntroLink } from '/components/home/pageOnly/Intro'
import { PageLayout } from '/components/home/pageOnly/Page'
import { Section, SectionAwards, SectionImageOnHover } from '/components/home/pageOnly/Section'
import { FeaturedArticle } from '/components/home/pageOnly/FeaturedArticle'
import { Cta } from  '/components/pageOnly/Cta'
import { Header } from '/components/cases/pageOnly/Header'
import { Marquee } from '/components/buildingBlocks/marquee/Marquee'
import { CasesGrid } from '/components/pageOnly/CasesGrid'

import styles from './Home.css'

const query = groq`
  ${getSingletonTypeById('home')} {
    title,
    showreel,
    featuredArticle-> {
      "image": header.headerImage,
      title,
      "slug": slug.current,
      contributors[]-> {
        _id,
        firstName,
        role
      }
    },
    "cases": *[_type == "case" && hidden != true] | order(documentOrder) [0...5] {
      'id': _id,
      'slug': slug.current,
      title,
      'image': header.headerImage {..., asset->},
      'client': header.client
    },
    "jobs": *[_type == "jobs"][0] {
      totalJobs
    }
  }
`

Home.meta = {
  title: 'Digitaal bureau voor intensieve interactie',
  description: 'Wij combineren strategie, creativiteit & technologie om mensen en merken een beetje verliefd op elkaar te laten worden. We weten wat kan, maar vooral wat werkt.',
  async fetchData(sanityClient, { isPreview = false }) {
    return {
      data: await sanityClient.fetch(query, { isPreview }),
      status: 200
    }
  }
}

export function Home() {
  useSetDocumentTitle(Home.meta.title)
  const { data } = usePageRouteData()
  const { cases = [], showreel, title, featuredArticle, jobs } = data || {}
  const sources = {
    1080: showreel?.quality1080,
    720: showreel?.quality720,
    540: showreel?.quality540,
    360: showreel?.quality360
  }

  const items = [
    'verrassen',
    'raken',
    'inspireren',
    'intrigeren',
    'uitdagen',
    'ontroeren',
    'verbazen',
    'motiveren',
    'prikkelen',
    'entertainen',
    'verwonderen',
  ]
  const doubledItems = [...items, ...items]

  return (
    <PageLayout totalJobs={jobs?.totalJobs}>
      <ContainerDark>
        <Hero {...{ sources, title }} />
      </ContainerDark>

      <ContainerLight>
        <SectionImageOnHover backgroundColor='#f8f8f8'>
          <ContentContainer width='md'>
            <IntroWithButton>
              <p>
                Wij zijn Kaliber, een digitaal bureau dat <IntroLink src={introGifAssets.strategie}>strategie</IntroLink>, <IntroLink src={introGifAssets.creativiteit}>creativiteit</IntroLink> en <IntroLink src={introGifAssets.technologie}>technologie</IntroLink> combineert om mensen iets te laten <IntroLink src={introGifAssets.begrijpen}>begrijpen</IntroLink>, <IntroLink src={introGifAssets.voelen}>voelen</IntroLink> en <IntroLink src={introGifAssets.doen}>doen</IntroLink>. We weten wat er <IntroLink src={introGifAssets.kan}>kan</IntroLink> en vooral wat er <IntroLink src={introGifAssets.werkt}>werkt</IntroLink>. Het resultaat is <IntroLink src={introGifAssets.interactie}>intensieve interactie</IntroLink> waardoor we mensen en merken een <IntroLink src={introGifAssets.verliefd}>beetje verliefd op elkaar laten worden</IntroLink>.
              </p>
            </IntroWithButton>
          </ContentContainer>
        </SectionImageOnHover>
      </ContainerLight>

      <ContainerColored>
        <SectionImageOnHover backgroundColor={'var(--color-pink)'} color='black'>
          <ContentContainer width='lg'>
            <Clients entries={clients} />
          </ContentContainer>
        </SectionImageOnHover>
      </ContainerColored>

      <ContainerDark>
        <Header>
          <Marquee
            title='cases die je'
            items={doubledItems}
            animationSpeed='80s'
          />
        </Header>
        <Section backgroundColor='black' color='white'>
          {cases && (
            <Cases {...{ cases }} />
          )}
          <div className={styles.buttonCases}>
            <LinkButtonWhite to={routes.cases.path}>
              Naar alle cases
            </LinkButtonWhite>
          </div>
        </Section>
      </ContainerDark>

      <ContainerColored>
        <SectionAwards>
          <ContentContainer width='lg'>
            <Awards>
              <AwardsItem src={awards.ADCN} name='ADCN' />
              <AwardsItem src={awards.DIA} name='Dutch Interactive Awards' />
              <AwardsItem src={awards.SAN} name='SAN' />
              <AwardsItem src={awards.Epica} name='Epica Awards' />
              <AwardsItem src={awards.Eurobest} name='Eurobest' />
              <AwardsItem src={awards.Lovie} name='Lovie Awards' />
              <AwardsItem src={awards.Pixel} name='Pixel Awards' />
              <AwardsItem src={awards.SJP} name='SJP Magneet' />
              <AwardsItem src={awards.Spin} name='Spin Awards' />
              <AwardsItem src={awards.Webby} name='The Webby Awards' />
            </Awards>
          </ContentContainer>
        </SectionAwards>
      </ContainerColored>

      {featuredArticle && (
        <ContainerLight>
          <Section backgroundColor='white' color='black'>
            <FeaturedArticle
              title={featuredArticle.title}
              slug={featuredArticle.slug}
              contributors={featuredArticle.contributors}
              image={featuredArticle.image}
            />
          </Section>
        </ContainerLight>
      )}

      <ContainerDark>
        <Section backgroundColor='black'>
          <ContentContainer width='lg'>
            <Cta text='Ontdekken wat Kaliber voor je merk kan betekenen? Neem contact op!' />
          </ContentContainer>
        </Section>
      </ContainerDark>
    </PageLayout>
  )
}

function Cases({ cases }) {
  return (
    <div className={styles.componentCases}>
      <CasesGrid {...{ cases }} />
    </div>
  )
}
