import { FollowCursor } from '/components/buildingBlocks/FollowCursor'
import { ButtonCircle } from '/components/buildingBlocks/ButtonCircle'
import { IconRightArrow, IconHandLeft, IconHandRight, IconHandPointing } from '/components/buildingBlocks/Icon'
import { useMediaQuery } from '@kaliber/use-media-query'
import { routes, determineDocumentPath } from '/routes'
import { Image } from '/components/Image'
import { useSanityClient } from '/machinery/SanityClient'
import { useKeenSlider } from 'keen-slider/react'
import { IconContainerClipped } from './IconContainer'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './HoverCarousel.css'

function hoverAutoScrollPlugin(slider) {
  let id

  let distance = 0
  let direction = 0
  let mouseX = 0

  slider.on('created', () => {
    slider.container.addEventListener('mouseenter', animate)
    slider.container.addEventListener('mousemove', handleMousePostition)
    slider.container.addEventListener('mouseleave', stop)
    slider.container.addEventListener('mousedown', stop)

    function animate() {
      slider.track.add(direction * distance)
      id = requestAnimationFrame(animate)
    }
  })

  slider.on('destroyed', stop)

  function stop() {
    cancelAnimationFrame(id)
  }

  function handleMousePostition(e) {
    const { innerWidth } = window
    const minSpeed = 0.001
    const maxSpeed = 0.04
    const edgeDistance = direction ? (innerWidth) - mouseX : mouseX
    const normalizedDistance = 1 - edgeDistance / innerWidth

    distance =  minSpeed + Math.pow(normalizedDistance, 2) * (maxSpeed - minSpeed)
    direction = Math.sign(e.x - (innerWidth / 2))
    mouseX = e.clientX - (innerWidth / 2)
  }
}

export function HoverCarousel({ cases, type }) {
  const isMd = useMediaQuery(mediaStyles.viewportMd)

  const [carouselRef] = useKeenSlider(
    {
      loop: true,
      mode: 'free-snap',
      slides: { perView: 'auto', spacing: 37 }
    },
    isMd ? [] : [hoverAutoScrollPlugin]
  )

  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        <FollowCursor
          Cursor={({ isFollowing, x, xPercentage }) => (
            <IconContainerClipped isActive={isFollowing}>
              {xPercentage < 30
                ? <IconHandRight />
                : xPercentage > 70
                  ? <IconHandLeft />
                  : <IconHandPointing />
              }
            </IconContainerClipped>
          )}
        >
          <div ref={carouselRef} className={cx(styles.carousel, 'keen-slider')}>
            {cases?.map((item, i) => {
              const link = determineDocumentPath({ document: { _type: type, slug: { current: item.slug } }, routes })
              return (
                <div key={i} className={cx(styles.item, 'keen-slider__slide')}>
                  <HoverCarouselImage
                    image={item.image}
                    layoutClassName={styles.imageLayout}
                  />
                  <div className={styles.client}>{item.client}</div>
                  <a href={link} className={styles.link} data-x='link-to-case'>{item.title}</a>
                  <ButtonCircle layoutClassName={styles.buttonLayout}>
                    <IconRightArrow />
                  </ButtonCircle>
                </div>
              )
            })}
          </div>
        </FollowCursor>
      </div>
    </div>
  )
}

function HoverCarouselImage({ image, layoutClassName }) {
  const { imageBuilder } = useSanityClient()
  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.image} />
    </div>
  )
}
