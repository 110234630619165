import { useSpring, animated, config } from 'react-spring'
import { IconContainer } from '/components/buildingBlocks/IconContainer'
import { useHasMouse } from '/machinery/HasMouse'
import styles from './FollowCursor.css'

export function FollowCursor({ children, Cursor, onCursorMove = undefined, onMouseLeave = undefined, onMouseEnter = undefined }) {
  const { hasMouse } = useHasMouse()
  const componentRef = React.useRef()
  const [{ x, y, xPercentage }, setCursorPosition] = React.useState({ x: 0, y: 0, xPercentage: 0 })
  const [isFollowing, setIsFollowing] = React.useState(false)

  React.useEffect(
    () => onCursorMove && onCursorMove({ x, y, xPercentage }),
    [x, y, xPercentage, onCursorMove]
  )

  const style = useSpring({
    config: isFollowing ? { tension: 250, friction: 18 } : config.slow,
    transform: `translate(${x}px, ${y}px)`
  })

  const mouseMoveHandler = React.useCallback(handleMouseMove, [hasMouse])
  const mouseLeaveHandler = React.useCallback(handleMouseLeave, [hasMouse])
  const mouseEnterHandler = React.useCallback(handleMouseEnter, [hasMouse])

  return (
    <div
      ref={componentRef}
      className={styles.component}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseMove={isFollowing ? mouseMoveHandler : undefined}
    >
      {children}
      <CursorPosition {...{ style }} layoutClassName={styles.cursorPosition}>
        <div className={styles.cursor}>
          <Cursor {...{ isFollowing, x, y, xPercentage }} />
        </div>
      </CursorPosition>
    </div>
  )

  function handleMouseMove({ clientX, clientY }) {
    if (!hasMouse) return
    const { top, left, width, height } = componentRef.current.getBoundingClientRect()

    setCursorPosition({
      x: (clientX - left) - (width / 2),
      y: (clientY - top) - (height / 2),
      xPercentage: (clientX / width) * 100
    })
  }

  function handleMouseLeave() {
    setIsFollowing(false)
    setCursorPosition({ x: 0, y: 0, xPercentage: 0 })
    onMouseLeave && onMouseLeave()
  }

  function handleMouseEnter() {
    setIsFollowing(true)
    onMouseEnter && onMouseEnter()
  }
}

function CursorPosition({ style, layoutClassName, children }) {
  return (
    <animated.div style={{ ...style, willChange: 'transform' }} className={layoutClassName}>
      {children}
    </animated.div>
  )
}

export function FollowCursorCursor({ children, isFollowing, layoutClassName = undefined }) {
  return (
    <IconContainer isActive={isFollowing} {...{ layoutClassName }}>
      {children}
    </IconContainer>
  )
}
