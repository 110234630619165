import { FollowCursor, FollowCursorCursor } from '/components/buildingBlocks/FollowCursor'
import { IconLeftArrow, IconRightArrow } from './Icon'
import Flickity from '/machinery/Flickity'
import { IconContainerClipped } from '/components/buildingBlocks/IconContainer'
import styles from './Carousel.css'

export function Carousel({ children, onSelect = null }) {
  const [Icon, setIcon] = React.useState(() => IconRightArrow)
  const componentRef = React.useRef(null)
  const options = { pageDots: false, prevNextButtons: false, wrapAround: true, draggable: true, dragThreshold: 3 }

  return (
    <div className={styles.component}>
      <FollowCursor
        Cursor={({ isFollowing }) => <FollowCursorCursor layoutClassName={styles.cursorLayout} IconContainer={IconContainerClipped} {...{ isFollowing }}><Icon /></FollowCursorCursor>}
      >
        <Flickity
          onClick={handleClick}
          ref={componentRef}
          {...{ options, children, onSelect }}
        />
      </FollowCursor>
    </div>
  )

  function handleClick({ clientX }) {
    if (!componentRef.current || !componentRef.current.flickity) return
    const { width, left } = componentRef.current.flickity.element.getBoundingClientRect()

    const isLeftSide = (clientX - left) < (width / 2)
    setIcon(() => isLeftSide ? IconLeftArrow : IconRightArrow)

    if ((clientX - left) < (width / 2)) componentRef.current.flickity.previous()
    else componentRef.current.flickity.next()
  }
}
