import  { animated, useSpring, config } from 'react-spring'

import styles from './CategoryFilter.css'

export function CategoryFilter({ layoutClassName, categories, selectedCategory, onSelectedCategoryChange }) {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const displayText = selectedCategory || 'Alles'

  function showFilters() {
    setIsExpanded(true)
  }

  function hideFilters() {
    setIsExpanded(false)
  }

  function handleCategoryChange(category) {
    onSelectedCategoryChange(category)
    setIsExpanded(false)
  }

  const style = useSpring({
    opacity: `${isExpanded ? 1 : 0}`,
    transform: `${isExpanded ? '-15' : 0}px`,
    config: config.gentle,
    delay: !isExpanded && 500,
  })

  return (
    <div className={cx(styles.component, layoutClassName)} onMouseEnter={showFilters} onMouseLeave={hideFilters}>
      <span className={styles.filtersLabel}>Filter</span>
      <span className={styles.filterLine} />
      <div className={styles.filtersWrapper}>
        <button
          className={styles.filtersToggle}
          onClick={() => handleCategoryChange(null)}
        >
          {displayText}
        </button>
        <animated.div className={styles.expandedFilters} {...{ style }}>
          {selectedCategory && (
            <button
              onClick={() => handleCategoryChange(null)}
              className={cx(styles.button, selectedCategory && styles.activeFilter)}
            >
              Alles
            </button>
          )}
          {categories
            .filter(x => x !== displayText)
            .map((category, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleCategoryChange(category)}
                  className={cx(styles.button, selectedCategory && styles.activeFilter)}
                >
                  {category}
                </button>
              )
            })}
        </animated.div>
      </div>
    </div>
  )
}


