
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { CasesGrid } from '/components/pageOnly/CasesGrid'
import { Marquee } from '/components/buildingBlocks/marquee/Marquee'
import { ContainerDark } from '/components/home/Container'
import { Section } from '/components/home/pageOnly/Section'
import { PageLayoutDefault } from '/components/pageOnly/PageLayout'
import { Header } from '/components/cases/pageOnly/Header'
import { getPreviewForOverviewType } from '/machinery/groqSnippets'
import { CategoryFilter } from '/components/buildingBlocks/CategoryFilter'

import groq from 'groq'


import styles from './Cases.css'

const query = groq`
  ${getPreviewForOverviewType('case')} | order(documentOrder) {
    'slug': slug.current,
    'id': _id,
    'client': header.client,
    'image': header.headerImage {..., asset->},
    'categories': categories,
    title,
  }
`

Cases.meta = {
  title: 'Cases',
  description: '',
  async fetchData(sanityClient, { isPreview = false }) {
    const cases = await sanityClient.fetch(query, { isPreview })
    return {
      data: { cases },
      status: 200,
    }
  },
}

export function Cases() {
  useSetDocumentTitle(Cases.meta.title)
  const { isLoading, isError, data } = usePageRouteData()
  const { cases = [] } = data || {}
  const [selectedCategory, setSelectedCategory] = React.useState(null)

  const items = [
    'verrassen',
    'raken',
    'inspireren',
    'intrigeren',
    'uitdagen',
    'ontroeren',
    'verbazen',
    'motiveren',
    'prikkelen',
    'entertainen',
    'verwonderen',
  ]
  const doubledItems = [...items, ...items]

  const categories = [
    'Branding',
    'Campagne',
    'Platform',
    'Strategie',
    'Employer Branding',
    'Packaging',
    'Content',
    'Installaties',
  ]

  const currentlyUsedCategories = cases.filter(x => x?.categories?.length > 0).map(x => x.categories).flatMap(x => x)
  const availableCategories = categories.filter(x => currentlyUsedCategories.includes(x))

  const filteredCases = selectedCategory
    ? cases?.filter(caseItem => caseItem?.categories?.includes(selectedCategory))
    : cases || []

  return (
    <PageLayoutDefault>
      <ContainerDark>
        <Header>
          <Marquee
            title='cases die je'
            items={doubledItems}
            animationSpeed='80s'
          />
        </Header>
      </ContainerDark>
      <ContainerDark>
        <Section backgroundColor='black' color='white'>
          <div className={styles.casesContainer}>
            {isLoading && <p>Laden...</p>}
            {isError && <p>Er is een fout opgetreden</p>}
            {data && cases.length === 0 && <p>Geen resultaten</p>}
            {data &&  (
              <>
                {(availableCategories.length > 0) && (
                  <CategoryFilter
                    categories={availableCategories}
                    layoutClassName={styles.filtersLayout}
                    onSelectedCategoryChange={setSelectedCategory}
                    {...{ selectedCategory }}
                  />
                )}
                <CasesGrid cases={filteredCases} />
              </>
            )}
          </div>
          <article />
        </Section>
      </ContainerDark>
    </PageLayoutDefault>
  )
}
