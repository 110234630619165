import styles from './IconContainer.css'
import cx from 'classnames'

export function IconContainerClipped({ children, isActive, layoutClassName = undefined }) {
  return <IconContainerBase className={cx(styles.componentClipped, layoutClassName)} {...{ children, isActive }} />
}

export function IconContainer({ children, isActive, layoutClassName }) {
  return <IconContainerBase className={cx(styles.component, layoutClassName)} {...{ children, isActive }} />
}

function IconContainerBase({ children, isActive, className }) {
  return (
    <span className={cx(styles.componentBase, isActive && styles.isActive, className)}>
      {children}
    </span>
  )
}
